import React from 'react';
import { Modal, Button, Stack, Title, Group, Text, Divider } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import QuestionSVG from "../../../svgs/question_pop_up.svg";

interface AwarenessAssessmentExitAlertProps {
    modalOpened: boolean;
    onModalClose: () => void;
  }

const AwarenessAssessmentExitAlert: React.FC<AwarenessAssessmentExitAlertProps> = ({modalOpened, onModalClose}) =>  {
    const history = useHistory();
    const { t } = useTranslation("awareness-assessment");

    const onExit = () => {
        history.push("/");
    };

    return (
            <Modal
                opened={modalOpened}
                title="" onClose={() => {}} withCloseButton={false}
                size={"480px"}
            >
                <Stack>
                    <Stack gap="xl" px="32px" py="24px" align='center'>
                        <QuestionSVG width="87px" height="87px"/>
                        <Stack align='center'>  
                            <Title order={5}>{t("warningTitle")}</Title>
                            <Text ta="center">{t("warningDescription")}</Text>
                        </Stack>
                    </Stack>
                <Divider />
                <Group justify='end'>
                    <Button size="sm" aria-label={t("exit")} variant="secondary" onClick={onExit}>{t("exit")}</Button>
                    <Button size="sm" aria-label={t("continueAssessment")} onClick={onModalClose}>{t("continueAssessment")}</Button>
                </Group>
                </Stack>
            </Modal>
    )};

export default AwarenessAssessmentExitAlert;