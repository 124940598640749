import React, { useEffect, useMemo } from "react";

import {
  ArrowRightIcon,
  CheckCircleIcon,
  VeraAnnotationXIcon,
  Tag,
} from "@sosafe-platform-engineering/fe-lib-ui-react";

import { Card, Progress } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import Loading from "shared/components/loading";
import { Survey } from "survey-react-ui";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { TagProps } from "@sosafe-platform-engineering/fe-lib-ui-react/dist/components/tag";
import { useTranslation } from "react-i18next";
import { SurveySummaryResult } from "flamingo-e-learning-platform/awareness-assessment/types";
import { isSurveyPassed } from "../../utils/survey-utils";
import { AwarenessAssessmentFeedback } from "../awareness-assessment-feedback";
import style from "./awareness-assessment-survey.module.css";
import "../awareness-assessment-sosafe-survey-style.css";
import "../awareness-assessment-ranking.css";

interface AwarenessAssessmentSurveyProps {
  surveyData: any;
  module: PersonalizedModule;
  onComplete: () => void;
  setIsNextDisabled: (value: boolean) => void;
  surveyResult: Array<SurveySummaryResult> | null;
}

export const AwarenessAssessmentSurvey = ({
  surveyData,
  module,
  onComplete,
  setIsNextDisabled,
  surveyResult,
}: AwarenessAssessmentSurveyProps) => {

  const { t } = useTranslation("awareness-assessment");

  const onCurrentPageChanged = () => {
    setIsNextDisabled(true);
  };

  const onValueChanged = () => {
    if (surveyData) {
      const answer = surveyData.getValue(surveyData.activePage.questions[0].name);
      setIsNextDisabled(!answer?.length);
    }
  };

  useEffect(() => {
    const firstCheckbox = document.querySelector('input[type="checkbox"]') as HTMLInputElement;
    if (firstCheckbox) {
      firstCheckbox.focus();
    }
  }, []);

  const tagProps = useMemo((): Pick<TagProps, "variant" | "icon"> => {
    if (!surveyResult || surveyResult === null)
      return { icon: ArrowRightIcon, variant: undefined };
    const passed = isSurveyPassed(surveyResult);
    if (passed) return { icon: CheckCircleIcon, variant: "success" };
    return { icon: VeraAnnotationXIcon, variant: "error" };
  }, [surveyResult]);

  if (!surveyData) return <Loading />;

  return (
    <div className={style.page}>
      <Card withBorder radius={"lg"} className={`${style.container}`}>
        <div className={`${style.header}`}>
          <Tag
            className={style.tag}
            variant={tagProps.variant}
            content={`${t("lesson")}: ${module.name}`}
            icon={tagProps.icon}
          />
          {!surveyResult && (
            <div className={`${style.progressbarContainer}`}>
              <Progress
                role="progressbar"
                aria-valuenow={surveyData.currentPageNo}
                aria-valuemin={1}
                aria-valuemax={surveyData.pageCount}
                aria-labelledby="survey-progress"
                className={`${style.progressbar}`}
                value={Math.floor(
                  (surveyData.currentPageNo / surveyData.pageCount) * 100
                )}
              />
              <label id="survey-progress">
                <span className="sr-only">{t("step")}</span>
                <span>{`${surveyData.currentPageNo + 1} ${t(
                  "outOf"
                )} ${surveyData?.pageCount}`}</span>
                <span className="sr-only">{t("steps")}</span>
              </label>
            </div>
          )}
        </div>
        {!surveyResult && (
          <Survey
            model={surveyData}
            onCurrentPageChanged={onCurrentPageChanged}
            onValueChanged={onValueChanged}
            onComplete={onComplete}
            className={`${style.survey}`}
          />
        )}
        {surveyResult && (
          <AwarenessAssessmentFeedback module={module} result={surveyResult} />
        )}
      </Card>
    </div>
  );
};
