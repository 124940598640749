import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { MODULE_STATUS } from "flamingo-e-learning-platform/training/types/module-status.type";
import useUser from "shared/hooks/use-user";
import { sortCompleteModuleToLast } from "../shared/sort-complete-module-to-last";
import { ModuleCardController } from "../module-card/module-card-controller";
import { PathModuleCard } from "../module-card/path-module-card/path-module-card";
import { CarouselComponent } from "../carousel/carousel";
import filteredLearningPath from "../shared/filter-learning-path";
import FLAGS from "../shared/check-for-flags";
import style from "./learning-path.module.css";

const LIMIT_DISPLAYED_MODULES = 5;

type LearningPathType = {
  onModuleCard: (module: PersonalizedModule) => void;
  mandatoryModules: PersonalizedModule[];
};

export const LearningPath = (props: LearningPathType) => {
  const { onModuleCard, mandatoryModules } = props;
  const { t } = useTranslation("flamingo");
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1100);
  const { user } = useUser({});
  // Check if the user is in the learning path customers array
  const showLimitedModules =
    user?.customer.id &&
    FLAGS.learningPathCustomersArray.includes(parseInt(user?.customer.id, 10));

  const handleScreenResize = () => {
    const isItTablet = window.innerWidth < 1100;
    setIsTablet(isItTablet);
  };

  useEffect(() => {
    window.addEventListener("resize", handleScreenResize, false);
  }, []);

  const allPathLearningModulesComplete = useMemo(
    () => mandatoryModules.every((mod) => mod.status === MODULE_STATUS.RESTART),
    [mandatoryModules]
  );

  const sortedModulesByComplete = useMemo(
    () =>
      allPathLearningModulesComplete
        ? mandatoryModules
        : sortCompleteModuleToLast(mandatoryModules),
    [allPathLearningModulesComplete, mandatoryModules]
  );

  const limitedModules = useMemo(
    () =>
      filteredLearningPath(sortedModulesByComplete, FLAGS.learningPathLimit),
    [sortedModulesByComplete]
  );

  const sliceModules = (modules: PersonalizedModule[]) =>
    modules.slice(0, LIMIT_DISPLAYED_MODULES);

  // Show limited content if the user is in the list for limited learning path customers array
  const displayedModules = showLimitedModules
    ? sliceModules(limitedModules)
    : sliceModules(sortedModulesByComplete);

  return (
    <div>
      <div className={style.header}>
        <h2 className={style.headerText}>{t("learning-path")}</h2>
      </div>
      <div className={style.content}>
        <div className={style.modules}>
          {displayedModules && (
            <CarouselComponent
              slides={isTablet ? 1 : 2}
              components={displayedModules.map((mod) => (
                <ModuleCardController
                  key={mod.name}
                  buttonSize="sm"
                  highLight={mod.id === displayedModules[0].id}
                  {...mod}
                  onAction={onModuleCard}
                  ModuleVariant={PathModuleCard}
                />
              ))}
            />
          )}
        </div>
      </div>
    </div>
  );
};
