const isCertificateActivated = process.env.CERTIFICATE_ACTIVE === "true";
const isAwarenessAssessment = process.env.AWARENESS_ASSESSMENT_ACTIVE === "true";
const isPartnerFeatureActivated = process.env.PARTNER_FEATURE_ACTIVE === "true";

// Check if there is a limit for learning path
const learningPathLimitFlag = process.env.LEARNING_PATH_LIMIT_DAYS;
const learningPathLimit = Number.isNaN(Number(learningPathLimitFlag)) || !learningPathLimitFlag ? 0 : parseInt(learningPathLimitFlag, 10);

// Check if there is a customers ids array for learning path
const learningPathCustomersArrayFlag = process.env.LEARNING_PATH_LIMIT_CUSTOMER_IDS;
const learningPathCustomersArray = learningPathCustomersArrayFlag
? learningPathCustomersArrayFlag
    .split(",")
    .map((str) => parseInt(str.trim(), 10))
: [];

export default { 
    isCertificateActivated, 
    isAwarenessAssessment, 
    isPartnerFeatureActivated, 
    learningPathLimit, 
    learningPathCustomersArray 
};


